@import 'variables';

// styles to allowing registration body content to fill page
body.let-body-fill-page {

	#app-routes-component {
		display: grid;
		grid-template-rows: 1fr auto;
		height: 100%;
		justify-content: stretch;

		.page-wrapper-component {
			display: grid;
			grid-template-rows: auto 1fr;
			height: 100%;
			overflow: hidden;

			.culture-select {
				background: #F6F7F8
			}

			.internal-header {
				.site-header {
					margin-bottom: 0;
				}
			}

			.body-content {
				height: 100%;
				overflow: hidden;
				background: #F6F7F8;

			}
		}

		#bottom-footer {
			justify-self: stretch;
			padding: $space-sm $space-lg;
		}
	}
}

.account-settings-section.fill-page {
	height: 100%
}

.driver-role-information-section.fill-page {
	height: 100%;
}
